import React from 'react';
import { NavLink } from 'react-router-dom';
import { Button, Col, Container, Row } from 'react-bootstrap';

function Main() {
    return (
        <Container>
            <Row>
                <Col sm={12}>
                    <h2>karaoke helper</h2>
                </Col>
            </Row>
            <Row>
                <Col sm={12}>
                    <NavLink to="/lyrics">
                        <Button variant="primary">Create lyrics</Button>
                    </NavLink>
                </Col>
            </Row>
            <Row>
                <Col sm={12}>
                    <NavLink to="/ass">
                        <Button variant="primary" disabled>Add effects to ass</Button>
                    </NavLink>
                </Col>
            </Row>
        </Container>
    );
}

export default Main;