import React from 'react';
import { Card } from 'react-bootstrap';

function LyricLine(props) {
    let highlightClass = '';
    let lineToOutput = props.line;

    if (lineToOutput.indexOf('{\\c&H00FFEB&}') >= 0) {
        highlightClass = 'current-lyric';
        lineToOutput = lineToOutput.replace('{\\c&H00FFEB&}', '');
        lineToOutput = lineToOutput.replace('{\\c&HFFFFFF&}', '');
    }

    return (
        <Card.Text className={highlightClass}>{lineToOutput}</Card.Text>
    );
}

export default LyricLine;