import React from 'react';
import { Button } from 'react-bootstrap';

function CopyLyricButton(props) {
    const output = props.lines.join('\\N');
    const clickHandler = () => {
        navigator.clipboard
            .writeText(output)
            .then(() => { alert('Copied')})
            .catch(err => { alert('Copy failed')});
    }

    return <Button variant="primary" size="sm" onClick={clickHandler}>Copy</Button>;
}

export default CopyLyricButton;