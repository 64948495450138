import React from 'react';
import { Col, Container, Form, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';

import LyricsGenerator from './LyricsGenerator';

function LyricsMain() {
    const [lyricType, setLyricType] = React.useState('simple');
    const [lyrics, setLyrics] = React.useState('');

    return (
        <Container>
            <Form>
                <Form.Row>
                    <Col>
                    <h2>Create lyrics</h2>
                    </Col>
                </Form.Row>
                <Form.Group controlId="lyrics.type">
                    <ToggleButtonGroup type="radio" name="typeOfLyrics" defaultValue="simple" onChange={v => setLyricType(v)}>
                        <ToggleButton value="simple">Simple</ToggleButton>
                        <ToggleButton value="rolling">Rolling</ToggleButton>
                    </ToggleButtonGroup>
                </Form.Group>
                <Form.Group controlId="lyrics.text">
                    <Form.Label>Paste lyrics here</Form.Label>
                    <Form.Control as="textarea" rows="15" value={lyrics} onChange={v => setLyrics(v.target.value)}/>
                </Form.Group>
                <Form.Row>
                    <LyricsGenerator lyricType={lyricType} lyrics={lyrics} />
                </Form.Row>
            </Form>
        </Container>
    );
}

export default LyricsMain;