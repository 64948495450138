import React from 'react';
import { Card } from 'react-bootstrap';

import CopyLyricButton from './CopyLyricButton';
import LyricLine from './LyricLine';

function LyricFrame(props) {
    return (
        <Card style={{ width: '50rem' }}>
            <Card.Body>
                {props.lines.map((line, index) => (<LyricLine key={index} line={line} />))}
                <CopyLyricButton lines={props.lines} />
            </Card.Body>
        </Card>
    );
}

export default LyricFrame;