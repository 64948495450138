import React from 'react';
import { Route, BrowserRouter } from 'react-router-dom';

import Main from './Main';
import LyricsMain from './LyricsMain';
import "./App.css";
import "./css/bootstrap.min.css";

function App() {
    return (
        <BrowserRouter>
            <div className="App">
                <header className="App-header">
                    <Route exact path="/" component={Main} />
                    <Route exact path="/lyrics" component={LyricsMain} />
                </header>
            </div>
        </BrowserRouter>
    );
}

export default App;
