import React from 'react';

import LyricFrame from './LyricFrame';

function LyricsGenerator(props) {
    if (props.lyrics.length === 0) {
        return (
            <div>Type or paste some lyrics in the box above.</div>
        );
    }

    let data = [];

    if (props.lyricType === 'simple') {
        data = createDataForSimpleVerse(props.lyrics);
    } else if (props.lyricType === 'rolling') {
        data = createDataForRollingVerse(props.lyrics);
    }

    return (
        <React.Fragment>
            {data.map((row, index) => <LyricFrame key={index} lines={row} />)}
        </React.Fragment>
    );
}

function createDataForSimpleVerse(lyrics) {
    let lines = lyrics.split('\n');
    let output = [];

    for (let i = 0; i < lines.length; i++) {
        let thisOutput = [...lines];
        thisOutput[i] = `{\\c&H00FFEB&}${lines[i]}{\\c&HFFFFFF&}`;
        output.push(thisOutput);
    }

    return output;
}

function createDataForRollingVerse(lyrics) {
    const numberOfLines = 4;
    let lines = lyrics.split('\n');
    let output = [];

    let currentLines = lines.filter((val, index) => index < 4);
    let currentLineIndex = 0;
    let currentOutput = [];

    for (let i = 0; i < lines.length; i++) {
        currentOutput = [...currentLines];
        currentOutput[currentLineIndex] = `{\\c&H00FFEB&}${currentLines[currentLineIndex]}{\\c&HFFFFFF&}`;
        output.push(currentOutput);

        currentLines[currentLineIndex] = i + 4 < lines.length ? lines[i + 4] : '';

        currentLineIndex++;
        if (currentLineIndex >= numberOfLines) {
            currentLineIndex = 0;
        }      
    }

    return output;
}

export default LyricsGenerator;